@font-face {
  font-family: 'CustomFont';
  src: url('./assets/font/ocraextended.ttf'),;
  font-display: swap;
}


.font-orca{
    font-family: 'CustomFont';
  }
  