@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background: linear-gradient(
    to right,
    rgba(233, 64, 0, 0.1) 0%,
    rgba(233, 64, 0, 0.3) 100%
  );
}

.header {
  background: linear-gradient(to right, #fd591b 0%, #e94000 100%);
  border-radius: 0px 0px 20px 20px;
}

.header-inner {
  height: 92%;
  border-radius: 0px 0px 20px 20px;
  border-bottom: 1px dashed #f5a181;
  border-right: 1px dashed #f5a181;
  border-left: 1px dashed #f5a181;
}

.top-7-5 {
  top: 7.5rem;
}

.text-xs-custom {
  font-size: 12px;
  font-weight: 400;
  line-height: 13.44px;
}

.small-line-height {
  line-height: 8.58px;
}

.text-md-normal-custom {
  font-size: 15px;
  font-weight: 400;
  line-height: 16.8px;
}

.text-md-normal-custom-v2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 15.68px;
  color: white;
}

.text-md-normal-custom-v3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 16.8px;
  color: white;
}

.text-md-normal-custom-v4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 15.68px;
  color: black;
}

.text-md-normal-custom-v5 {
  font-size: 11px;
  font-weight: 400;
  line-height: 15.68px;
  color: white;
}

.text-md-normal-custom-v6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  color: white;
}

.text-md-normal-custom-v7 {
  font-size: 10px;
  font-weight: 400;
  line-height: 11.2px;
  color: white;
}

.text-md-normal-custom-v8 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;  
  color: black;
}

.text-lg-bold-custom {
  font-size: 20px;
  font-weight: 400;
  line-height: 22.4px;
}

.text-lg-bold-custom-v2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  color: white;
}

.text-lg-bold-custom-v3 {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.63px;
  color: white;
}

.text-lg-bold-custom-v4 {
  font-size: 10px;
  font-weight: 700;
  line-height: 12.19px;
  color: white;
}

.text-lg-bold-custom-v5 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  color: black;
}

.text-lg-bold-custom-v6 {
  font-size: 10px;
  font-weight: 700;
  line-height: 12.19px;
  color: black;
}
.text-xs-custom-place-holder {
  font-size: 10px;
  font-weight: 400;
  color: #7d6860;
}

.bottom-border-v1 {
  border-bottom: 1px dashed #7d655c;
}

.bottom-border-v2 {
  border-bottom: 1px dashed white;
}

.bottom-border-v3 {
  border: 1px solid #e94000;
}

.bottom-border-v4 {
  border: 1px dashed #7d736f;
}


.bottom-border-v5 {
  border: 1px solid #e93f00;
}

.profile-pic {
  object-fit: cover;
  width: 142px;
  height: 142px;
}

.tier-level {
  margin-left: 10px;
  top: 590px;
}

.w-245{
  width: 245px;
}






.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
  padding: 20px;
  color: white;
}

.landing-page header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  width: 100%;
  text-align: center;
}

.landing-page main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.landing-page button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.welcome-box {
  padding: 2rem;
  border-radius: 1rem;
  background: url('/src/assets/svg/passport-tier.svg') no-repeat center;
  background-size: cover;
  background-color: rgba(233, 64, 0, 0.9); /* Add more opacity to ensure text visibility */
  max-width: 600px;
  width: 100%;
  text-align: left;
  margin-top: 5vh; /* Position one-third down the page */
  color: white;
}

.welcome-box h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.welcome-box p {
  margin-top: 0.5rem;
}

.link-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #E94000;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.center-button {
  display: flex;
  justify-content: center;
}